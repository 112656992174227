import { Carousel } from './Carousel'

const AppDemoSection = () => {
  return (
    <div className="flex flex-row flex-wrap lg:flex-nowrap justify-center items-center ">
      <div className="order-1 mt-64 lg:mt-80 w-full h-100 lg:h-80 mb-44 lg:mb-0 ">
        <Carousel />
      </div>
      <div className="max-w-[550px] lg:order-2 text-white lg:mr-10">
        <p className="font-bold text-5xl">
          Non solo <span className="text-primary">quiz</span>
          <br /> il tuo studio a 360°
        </p>
        <p className="text-white/80 mt-6 text-xl">{`Dopo ogni simulazione sarà l'app a indicarti dove sono le tue lacune. Seleziona per te gli argomenti che dovresti ripassare. Monitora i tuoi progressi con statistiche real time.`}</p>
      </div>
    </div>
  )
}

export default AppDemoSection
