import React, { useRef, useState } from 'react'
import app1 from '../assets/app1.png'
import app2 from '../assets/app2.png'
import app3 from '../assets/app3.png'
import app4 from '../assets/app4.png'

//@ts-ignore
import ReactCardCarousel from '../lib/react-card-carousel/Cards'

export const Carousel = () => {
  const CONTAINER_STYLE: any = {}

  const [selected, setSelected] = useState(0)

  const NOT_SELECTED_CARD_STYLE: any = {
    filter: 'blur(5px)',
  }

  return (
    <div style={CONTAINER_STYLE}>
      <ReactCardCarousel
        autoplay={true}
        autoplay_speed={3000}
        afterChange={(i: any) =>
          setTimeout(() => {
            setSelected(i)
          }, 100)
        }
      >
        <div style={selected === 0 ? {} : NOT_SELECTED_CARD_STYLE}>
          <img src={app1} className="w-80 rounded-20"></img>
        </div>
        <div style={selected === 1 ? {} : NOT_SELECTED_CARD_STYLE}>
          <img src={app2} className="w-80 rounded-20"></img>
        </div>
        <div style={selected === 2 ? {} : NOT_SELECTED_CARD_STYLE}>
          <img src={app3} className="w-80 rounded-20"></img>
        </div>
        {/* <div style={selected === 3 ? {} : NOT_SELECTED_CARD_STYLE}>
          <img src={app4} className="w-80 rounded-20"></img>
        </div> */}
      </ReactCardCarousel>
    </div>
  )
}
