import React from 'react'

export default function MMGPrivacyPolicy() {
  return (
    <iframe
      allowFullScreen
      src="https://www.iubenda.com/privacy-policy/52018523"
      title="Privacy Policy Special Quiz MGG"
      className="w-full h-full"
      style={{ height: window.innerHeight }}
    />
  )
}
