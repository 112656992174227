import { useState } from 'react'
import { osName } from 'react-device-detect'
import { sendFormData, sendFormPropsResult } from '../api/InternalPublicApi'
import FormBg from '../svg/form-bg.svg'
import { Button, ButtonVariant } from './Button'
import Input from './Input'

const ContactFormSection = () => {
  const [name, setName] = useState<string>()
  const [emailAddress, setEmailAddress] = useState<string>()
  const [phoneNumber, setPhoneNumber] = useState<string>()
  const [message, setMessage] = useState<string>()
  const [formSent, setFormSent] = useState(false)

  const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  }

  async function sendForm() {
    if (emailAddress && name && phoneNumber && message) {
      const result = await sendFormData(name, emailAddress, phoneNumber, message)
      if (result === sendFormPropsResult.fail) {
        alert("Errore nell'invio dei dati...")
      } else if (result === sendFormPropsResult.success) {
        setFormSent(true)
      }
    }
  }

  return (
    <div className="relative rounded-3xl overflow-hidden bg-primary">
      {osName !== 'iOS' && osName !== 'Android' && (
        <img
          src={FormBg}
          alt="Bg Vector"
          className="absolute overflow-hidden blur-lg bg-green-light ball -top-[150px]"
        />
      )}
      <div className="flex flex-row flex-wrap items-center justify-center p-4 py-10 lg:py-20">
        <div className="flex flex-col text-white z-10 text-border">
          {formSent && (
            <p className="text-5xl font-bold text-border py-10 border-solid">
              Grazie per averci contattato!<br></br>Ti risponderemo al più presto.
            </p>
          )}
          {!formSent && (
            <>
              <p className="text-5xl font-bold text-border ">
                Vuoi ulteriori <br></br>informazioni?
              </p>
              <p className="mt-6 text-lg">
                Utilizza questo form per contattarci,<br></br> provvederemo a risponderti entro 48 ore.
              </p>
            </>
          )}
        </div>
        {!formSent && (
          <div className="flex flex-col lg:ml-[100px] lg:min-w-[400px] gap-4 mt-5 z-10 lg:px-8">
            <Input type={'text'} label={'Nome e Cognome'} value={name} required valueSetter={setName} />
            <Input
              type={'email'}
              label={'Indirizzo email'}
              value={emailAddress}
              required
              valueSetter={setEmailAddress}
            />
            <Input type={'tel'} label={'Numero di telefono'} value={phoneNumber} valueSetter={setPhoneNumber} />
            <Input isTextArea type={'text'} label={'Messaggio'} value={message} valueSetter={setMessage} />
            <p className="mt-2 text-sm text-white">*Cliccando "Invia", accetti di essere contattato.</p>
            <Button
              variant={ButtonVariant.Primary}
              onPress={() => {
                if (!name || name.trim().length === 0) {
                  alert('Inserisci il tuo nome e cognome per continuare')
                  return
                }
                if (
                  (!emailAddress || emailAddress.trim().length === 0) &&
                  (!phoneNumber || phoneNumber.trim().length === 0)
                ) {
                  alert('Inserisci email o numero di telefono per essere contattato')
                  return
                }
                if (emailAddress && emailAddress.trim().length > 0 && !validateEmail(emailAddress)) {
                  alert('Indirizzo email non valido!')
                  return
                }
                sendForm()
              }}
            >
              Invia
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default ContactFormSection
