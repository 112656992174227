import React from 'react'

export default function SSMTermsAndConditions() {
  return (
    <iframe
      allowFullScreen
      src="https://www.iubenda.com/termini-e-condizioni/16173149"
      title="Privacy Policy Terms and Conditions"
      className="w-full h-full"
      style={{ height: window.innerHeight }}
    />
  )
}
