import React, { ReactNode } from 'react'
import appleStore from '../assets/images/apple-store.png'
import googlePlay from '../assets/images/google-play.png'
import computer from '../assets/images/computer.png'
import mmgLogo from '../assets/images/mmg-logo.png'
export enum ButtonVariant {
  Primary,
  Transparent,
  Secondary,
}

export enum ButtonImage {
  AppleStore = `apple-store`,
  GooglePlay = `google-play`,
  Computer = `computer`,
  MmgLogo = `mmg-logo`,
}

const IMAGE_MAP = {
  [ButtonImage.AppleStore]: appleStore,
  [ButtonImage.GooglePlay]: googlePlay,
  [ButtonImage.Computer]: computer,
  [ButtonImage.MmgLogo]: mmgLogo,
}
export interface ButtonProps {
  variant: ButtonVariant
  children?: ReactNode
  image?: ButtonImage
  onPress?: () => void
}

const VARIANT_STYLE_MAP = {
  [ButtonVariant.Primary]: `bg-primary border-white text-white bg-opacity-50 hover:bg-opacity-100`,
  [ButtonVariant.Transparent]: `text-white bg-transparent border-transparent px-1 py-1`,
  [ButtonVariant.Secondary]: `bg-primary/30 border-white text-white bg-opacity-50 hover:bg-opacity-100`,
}

export const Button: React.FC<ButtonProps> = ({ variant, children, image, onPress }) => {
  return (
    <div
      className={`flex max-w-10 cursor-pointer justify-center text-lg font-circular align-center border-2 rounded-2xl items-center py-3 px-6 ${VARIANT_STYLE_MAP[variant]}`}
      onClick={(e: any) => {
        e.preventDefault()
        onPress && onPress()
      }}
    >
      {image && <img src={IMAGE_MAP[image]} alt="Button Image" className={`${children ? 'mr-2' : ''} w-10 h-10`} />}
      {children}
    </div>
  )
}
