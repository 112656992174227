export type InputProps = {
  type?: React.HTMLInputTypeAttribute
  label: string
  value?: string
  required?: boolean
  isTextArea?: boolean
  valueSetter: (newValue: string) => void
}

const Input = ({ type, label, value, required, isTextArea, valueSetter }: InputProps) => {
  return (
    <div className="flex flex-1 flex-col ">
      {isTextArea && (
        <textarea
          placeholder={label}
          value={value ?? ''}
          required={required}
          onChange={e => valueSetter(e.target.value)}
          className={'flex-1 border-white/50 border-[2px] rounded-lg h-10 px-4 py-2 bg-white/80 text-black'}
        ></textarea>
      )}
      {!isTextArea && (
        <input
          autoComplete={'on'}
          placeholder={label}
          type={type ?? undefined}
          value={value ?? ''}
          required={required}
          className={'flex-1 border-white/50 border-[2px] rounded-lg h-10 px-4 py-2 bg-white/80 text-black'}
          onChange={e => valueSetter(e.target.value)}
        />
      )}
    </div>
  )
}

export default Input
