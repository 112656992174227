import hero from '../assets/images/hero-mockup.png'
import { Button, ButtonImage, ButtonVariant } from './Button'

import { osName } from 'react-device-detect'
export type HeroSectionProps = {
  contactFormRef: any
}

const HeroSection = ({ contactFormRef }: HeroSectionProps) => {
  return (
    <div className="flex flex-row items-center justify-center lg:flex-nowrap flex-wrap">
      <div className="text-white mr-xl lg:mr-20 z-10">
        <p className="text-5xl font-bold ">Special Quiz</p>
        <p className="text-white/90 mb-8 mt-4 text-xl ">
          L'app per ottimizzare il tuo tempo con simulazioni di qualità<br></br> e spiegazioni aggiornate, dove e quando
          vuoi.
          <br></br>
          <br></br>
          Guida al meglio la tua preparazione ai concorsi
          <br></br>
          <b>SSM</b> (Scuole di Specializzazione) e <b>MMG</b> (Medicina Generale)
        </p>

        <div className="flex gap-3 flex-row flex-wrap justify-center lg:justify-start">
          <Button
            image={ButtonImage.AppleStore}
            variant={ButtonVariant.Primary}
            onPress={() => window.open('https://apps.apple.com/it/app/id1524977832', '_blank', 'noopener,noreferrer')}
          ></Button>
          <Button
            image={ButtonImage.GooglePlay}
            variant={ButtonVariant.Primary}
            onPress={() =>
              window.open(
                'https://play.google.com/store/apps/details?id=com.specialquizapp',
                '_blank',
                'noopener,noreferrer'
              )
            }
          ></Button>

          {osName !== 'iOS' && osName !== 'Android' && (
            <Button
              image={ButtonImage.Computer}
              variant={ButtonVariant.Primary}
              onPress={() => window.open('https://ssm.specialquiz.it', '_blank', 'noopener,noreferrer')}
            >
              PC
            </Button>
          )}
        </div>
      </div>
      {osName !== 'iOS' && osName !== 'Android' && (
        <img src={hero} className="w-[700px] rounded-[20px] mt-8 lg:mt-0 z-10 " />
      )}
    </div>
  )
}

export default HeroSection
