import React from 'react'

export default function MMGTermsAndConditions() {
  return (
    <iframe
      allowFullScreen
      src="https://www.iubenda.com/termini-e-condizioni/52018523"
      title="Terms and Conditions Special Quiz MMG"
      className="w-full h-full"
      style={{ height: window.innerHeight }}
    />
  )
}
