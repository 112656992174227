import axios from 'axios'

const baseApiUrl = 'https://specialquiz.it/api/public/'

export enum sendFormPropsResult {
  success,
  fail,
}

export async function sendFormData(
  name: string,
  email: string,
  phone: string,
  message: string
): Promise<sendFormPropsResult> {
  try {
    const result = await axios.post(baseApiUrl + 'contactform', {
      email,
      name,
      message,
      phone,
    })
    if (!result) return sendFormPropsResult.fail
    return sendFormPropsResult.success
  } catch (error) {
    console.log(`Error sending form data...\n${error}`)
    return sendFormPropsResult.fail
  }
}
