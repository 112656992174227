export type FeatureCardProps = {
  imageSrc: string
  title: string
  caption: string
  cta?: string
  imageBorder?: boolean
}

const FeatureCard = ({ imageSrc, title, caption, cta, imageBorder }: FeatureCardProps) => {
  return (
    <div className="flex flex-1 flex-col lg:min-w-[300px] mt-2 ">
      <div
        className={
          'z-10 p-4 py-8 bg-black/70 rounded-2xl flex flex-col justify-center items-center border border-white/20'
        }
      >
        <img src={imageSrc} width={150} height={150} />
        <div className="flex flex-col pt-4 max-w-[500px]">
          <p className="text-white text-2xl pb-2 text-center">{title}</p>
          <p className="text-white/60 text-lg pb-4">{caption}</p>
          {cta && <p className={'text-primary text-lg font-bold'}>{cta}</p>}
        </div>
      </div>
    </div>
  )
}

export default FeatureCard
