import { Button, ButtonVariant } from './Button'

export type PlanCardProps = {
  tag?: string
  title: string
  caption: string
  price?: string
  buttonText: string
  highlight?: boolean
  features: string[]
  onPress: () => void
}

const PlanCard = ({ tag, title, caption, price, buttonText, highlight, features, onPress }: PlanCardProps) => {
  let bgClassName = 'flex flex-1 flex-col px-6 py-8 rounded-3xl backdrop-blur-[50px] border-[3px] min-w-[300px] card'
  if (highlight) {
    bgClassName += ' bg-green-lit border-green-light/80 shadow-xl shadow-green-light/20'
  } else {
    bgClassName += ' bg-green-lit/80 border-white/20'
  }

  return (
    <div className={bgClassName}>
      <p className="text-white text-2xl font-bold">{title}</p>
      <p className="text-white/80 mt-2">{caption}</p>
      <p className="text-4xl text-[#026bae] mt-2">{price}</p>

      <ul className="list-disc px-4 pt-4">
        {features.map((feature, index) => {
          return (
            <li key={index} className="text-white/90">
              {feature}
            </li>
          )
        })}
      </ul>

      <div className="pt-6 flex justify-center">
        <Button variant={highlight ? ButtonVariant.Primary : ButtonVariant.Secondary} onPress={onPress}>
          <p>{buttonText}</p>
        </Button>
      </div>
    </div>
  )
}

export default PlanCard
