import React from 'react'
import { createBrowserRouter } from 'react-router-dom'
import SSMPrivacyPolicy from './pages/SSMPrivacyPolicy'
import SSMTermsAndConditions from './pages/SSMTermsAndConditions'
import MMGPrivacyPolicy from './pages/MMGPrivacyPolicy'
import MMGTermsAndConditions from './pages/MMGTermsAndConditions'
import HomePage from './pages/HomePage'
import PrivacyPolicy from './pages/PrivacyPolicy'
import CookiePolicy from './pages/CookiePolicy'

export const router = createBrowserRouter([
  {
    path: '/',
    element: <HomePage />,
  },
  {
    path: '/ssm/privacy-and-cookie-policy',
    element: <SSMPrivacyPolicy />,
  },
  {
    path: '/ssm/terms-and-conditions',
    element: <SSMTermsAndConditions />,
  },
  {
    path: '/mmg/privacy-and-cookie-policy',
    element: <MMGPrivacyPolicy />,
  },
  {
    path: '/mmg/terms-and-conditions',
    element: <MMGTermsAndConditions />,
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolicy />,
  },
  {
    path: '/cookie-policy',
    element: <CookiePolicy />,
  },
  {
    path: '*',
    element: <HomePage />,
  },
])
